import React from "react"
import theme from "theme"
import { Theme, Image, Text, Button, Box, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Головна | Оренда WiXi Renale</title>
        <meta name={"description"} content={"Приготуйся до пригод"} />
        <meta property={"og:title"} content={"Головна | Оренда WiXi Renale"} />
        <meta property={"og:description"} content={"Приготуйся до пригод"} />
        <meta
          property={"og:image"}
          content={"https://fun.wixirenale.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://fun.wixirenale.com/img/bike.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://fun.wixirenale.com/img/bike.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://fun.wixirenale.com/img/bike.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://fun.wixirenale.com/img/bike.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://fun.wixirenale.com/img/bike.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://fun.wixirenale.com/img/bike.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 90px 0"
        background="#fffbfb"
        quarkly-title="Product-4"
      >
        <Override slot="SectionContent" align-items="center" />
        <Box
          min-width="100px"
          min-height="100px"
          display="block"
          grid-template-columns="repeat(3, 1fr)"
          grid-template-rows="auto"
          grid-gap="0 35px"
          md-grid-template-columns="1fr"
          md-grid-gap="40px 0"
          margin="0px 0px 0 0px"
          lg-margin="0px 0px 0 0px"
        >
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="row"
            align-items="center"
            md-flex-direction="column"
          >
            <Image
              src="https://fun.wixirenale.com/img/1.jpg"
              display="block"
              max-width="100%"
              margin="0px 0px 0 0px"
              height="500px"
              width="50%"
              object-fit="cover"
              sm-height="220px"
              md-width="100%"
              md-margin="0px 0px 50px 0px"
              sm-margin="0px 0px 35px 0px"
            />
            <Box
              min-width="100px"
              min-height="100px"
              padding="0px 80px 0px 80px"
              width="50%"
              lg-padding="0px 50px 0px 50px"
              md-width="100%"
              sm-padding="0px 0 0px 0"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 42px/1.2 --fontFamily-sans"
                text-align="left"
                lg-font="normal 600 36px/1.2 --fontFamily-sans"
              >
                WiXi Renale
              </Text>
              <Text
                margin="0px 0px 50px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
                lg-margin="0px 0px 40px 0px"
                sm-margin="0px 0px 30px 0px"
              >
                Ласкаво просимо до наступної пригоди на двох колесах! У WiXi Renale Rentals ми віримо, що кожна подорож починається з чудової поїздки. Незалежно від того, подорожуєте ви міськими вулицями чи досліджуєте мальовничі дороги, ми пропонуємо ідеальний мотоцикл, який відповідатиме вашим бажанням. Наш привітний персонал забезпечить вам безпроблемну оренду від початку до кінця.
              </Text>
              <Button
                font="normal 500 18px/1.5 --fontFamily-sans"
                href="/contacts"
                type="link"
                text-decoration-line="initial"
                padding="0 0 0 0"
                background="0"
                color="--primary"
              >
               Звʼязатися з нами
              </Button>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          align-items="end"
          grid-gap="0 80px"
          lg-grid-gap="0 50px"
          md-grid-template-columns="1fr"
        >
          <Box
            min-width="100px"
            min-height="100px"
            md-margin="0px 0px 50px 0px"
          >
            <Text
              margin="0px 0px 20px 0px"
              font="normal 600 42px/1.2 --fontFamily-sans"
            >
             Про нас
            </Text>
            <Text
              margin="0px 0px 70px 0px"
              font="--lead"
              lg-margin="0px 0px 40px 0px"
              color="#626970"
            >
             WiXi Renale Rentals - це ваш головний пункт призначення для високоякісного прокату мотоциклів. Завдяки різноманітному автопарку з найновішими моделями, ми задовольняємо потреби як початківців, так і досвідчених мотоциклістів. Наші мотоцикли проходять ретельне технічне обслуговування та перевірку, щоб забезпечити вашу безпеку та комфорт на дорозі. Звертайтеся до нас за надійним сервісом та незабутніми враженнями від їзди.
            </Text>
            <Image
              src="https://fun.wixirenale.com/img/2.jpg"
              display="block"
              width="100%"
              height="600px"
              object-fit="cover"
              border-radius="15px"
              sm-height="400px"
            />
          </Box>
          <Box min-width="100px" min-height="100px">
            <Box
              min-width="100px"
              min-height="100px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 35px 0px"
              border-width="0 0 1px 0"
              border-style="solid"
              border-color="--color-lightD2"
              padding="0px 0px 30px 0px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 500 25px/1.2 --fontFamily-sans"
              >
                Чому обирають нас?
              </Text>
              <Text margin="0px 0px 20px 0px" font="--base" color="#626970">
              Першокласний автопарк: У нашому асортименті є все - від спортивних до туристичних моделей - від перевірених виробників.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 35px 0px"
              border-width="0 0 1px 0"
              border-style="solid"
              border-color="--color-lightD2"
              padding="0px 0px 30px 0px"
            >
              <Text margin="0px 0px 20px 0px" font="--base" color="#626970">
              Гнучкі пакети прокату: Від погодинної оренди до тижневих пригод - ми пропонуємо різноманітні варіанти прокату відповідно до вашого графіку та бюджету.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 35px 0px"
              border-width="0 0 1px 0"
              border-style="solid"
              border-color="--color-lightD2"
              padding="0px 0px 30px 0px"
            >
              <Text margin="0px 0px 20px 0px" font="--base" color="#626970">
              Клієнтоорієнтований підхід: Ми ставимо ваші потреби на перше місце, надаючи підтримку 24/7 та забезпечуючи безпроблемний процес оренди.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              margin="0px 0px 70px 0px"
              lg-margin="0px 0px 40px 0px"
            >
              <Text margin="0px 0px 20px 0px" font="--base" color="#626970">
              Забронюйте велосипед вже сьогодні і відчуйте захоплення від відкритої дороги з WiXi Renale Rentals!
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
